
import Vue from "vue"
export default Vue.extend({
    name: 'System',
    components: {

    },
    props: {

    },
    data() {
        return {

        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
});
